@use 'sass:map';
@use '@identic/core' as identic;

@mixin colour($config-or-theme) {

  $acarcinom-theme: map.get($config-or-theme, acarcinom);

  $acarcinom-yellow: map.get($acarcinom-theme, acarcinom-yellow);
  $acarcinom-brown: map.get($acarcinom-theme, acarcinom-brown);

  .species-select {
    // Default species background to lightgrey to override 'accent' and appear unselected
    .species-choice-btn.mat-mdc-fab.mat-accent {
      --mdc-fab-container-color: lightgrey !important;
    }

    .pressed {
      background-color: $acarcinom-yellow;
      i {
        color: white !important;
      }
    }
  }

  .center-chart .highcharts-container {
    margin: 0 auto;
    width: 100% !important;;
  }

  .highcharts-axis-labels.highcharts-xaxis-labels span {
    color: black !important;
    font-weight: bold;
  }

  .highcharts-axis-labels.highcharts-yaxis-labels text {
    color: black !important;
    font-weight: bold;
  }

  // .highcharts-data-labels span {
  //   // width: 100px;
  //   word-break: break-word !important;
  //   // white-space: normal !important;
  // }

  /* Print Settings */
  @media print {
    // .page-break {
    //   page-break-after: always;
    // }
    // .keep-on-same-page {
    //   page-break-inside: avoid;
    // }
    mat-header-row, mat-row, mat-footer-row {
      height: 35px !important;
      min-height: 35px !important;
    }
  }
}

@mixin typography($config-or-theme) {
  // Get the typography config from the theme.
  // $typography-config: mat.get-typography-config($config-or-theme);
  // e.g. font-family: mat.font-family($typography-config);

  // @include identic.fa-typography(parser-message);
}

@mixin theme($config-or-theme) {
  @include colour($config-or-theme);
  @include typography($config-or-theme);
}
