/* You can add global styles to this file, and also import other style files */
// .mat-drawer-content.mat-sidenav-content, .mat-drawer-inner-container {
//   background-color: lightgrey !important;
// }

// .mat-mdc-card-header .mat-mdc-card-header-text {
//   width: 100% !important;
// }

// mat-html .note-editing-area span {
//   color:white;
//   cursor:default;
//   border-radius: 10px;
//   border: solid #dbdbdb8c 1px;
// }

// .mat-mdc-card.scrollable-content {
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;

//   > .mat-mdc-card-title-group {
//     display: block;
//   }

//   > .mat-mdc-card-content {
//     overflow-y: auto;
//   }
// }
// .header-app-name {
//   font-family: Montserrat !important;
// }

// body {
//   font-family: 'Libre Baskerville', serif;
// }
