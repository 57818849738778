// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat-theming;
@use 'sass:map';

// Plus imports for other components in your app.
@use '@identic/layout';
@use '@identic/controls';
@use '@identic/chat';
@use '@identic/licence';
@use '@identic/log-to-db';
@use '@identic/notification';
@use '../lib' as acarcinom;

// Include the common styles for Angular Material. We include this here so that you only have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-theming.all-component-typographies();
@include mat-theming.core();

$acarcinom-yellow: #FCBB21;
$acarcinom-brown: #A59E8A;

$app-cutomisations: (
  acarcinom: (
    // Some settings
    acarcinom-yellow: $acarcinom-yellow,
    acarcinom-brown: $acarcinom-yellow,
  ),
  layout: (
    toolbar-height: 150px,
    mobile-toolbar-height: 16px,
  ),
  view-ppt: (
    // active-slide-colour: #6495ed9e,
    // nav-panel-min-width: 250px,
    // nav-icon-width: 48px,
    video-container-background: '/assets/logo-ACARCinom.jpg',
  ),
);

@mixin app-theme($theme-config) {
  $app-theme: map.merge($theme-config, $app-cutomisations);

  // Add Amgular material controls theme
  @include mat-theming.all-component-themes($app-theme);
  // Apply the custom typography
  // @include mat-theming.all-component-typographies($custom-typography);

  // Add themes for identic components used in this app
  @include layout.lib-theme($app-theme);
  @include controls.lib-theme($app-theme);
  @include chat.lib-theme($app-theme);
  @include licence.lib-theme($app-theme);
  @include log-to-db.lib-theme($app-theme);
  @include notification.lib-theme($app-theme);
  @include acarcinom.lib-theme($app-theme);
}

// Default palettes
$accent-default-palette: mat-theming.define-palette(mat-theming.$yellow-palette, A200, A100, A400);
$warn-default-palette: mat-theming.define-palette(mat-theming.$red-palette);

@mixin app-define-light-and-dark-themes($theme-name, $primary-palette, $accent-palette: $accent-default-palette, $warn-palette: $warn-default-palette) {
  .#{$theme-name} {
    &-theme {

      // Hamburger menu icon
      .app-toolbar .mat-mdc-icon-button.mat-primary {
        --mdc-icon-button-icon-color: black !important;
      }

      @include app-theme(mat-theming.define-light-theme((
        color: (
          primary: $primary-palette,
          accent: $accent-palette,
          warn: $warn-palette,
        ),
        // typography: $custom-typography,
        // density: 0,
      )));

      // Pointer cursor to show logo is clickable
      .site-logo {
        cursor: pointer;
      }

      .profile-menu-item {
        &,&:hover,&:focus {
          // Menu profile name black on yellow
          background-color: #fdbc1f !important;
          .mdc-list-item__primary-text {
            color: black !important;
          }
        }
      }

      .mat-mdc-card-header {
        padding: 5px 5px 0;
      }

      .mat-toolbar {
        background: white !important;
      }
      .header-app-name, .login-section {
        color: black !important;
      }

      // grid-editor .mat-mdc-card-header {
      //   background-color: lightblue;
      // }

      // Remove padding above profile item
      .mat-mdc-nav-list {
        padding-top: inherit;
      }
    }
    // &-dark-theme {
    //   @include app-theme(mat-theming.define-dark-theme((
    //     color: (
    //       primary: $primary-palette,
    //       accent: $accent-palette,
    //       warn: $warn-palette,
    //     ),
    //     typography: $custom-typography,
    //     density: 0,
    //   )));
    // }
  }
}

// ------------------------------------------------
// Themes that can be set with /theme/<theme-name>

// i.e. ?/theme=default or ?/theme=default-dark
@include app-define-light-and-dark-themes(default, mat-theming.define-palette(mat-theming.$blue-palette));

// i.e. ?/theme=uq or ?/theme=uq-dark
// @include app-define-light-and-dark-themes(default, mat-theming.define-palette(layout.$uq-palette));
