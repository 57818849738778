@use 'sass:map';
@use '@identic/core' as identic;

$fa-parser-message-icons: (
  information: (colour: blue, fa-content: '\f05a\00a0'),  // fa-info-circle &nbsp;
  reminder: (colour: green, fa-content: '\f848\00a0'),    // fa-bell-exclamation &nbsp;
  warning: (colour: orange,fa-content: '\f071\00a0'),     // fa-exclamation-triangle &nbsp;
  error: (colour: red, fa-content: '\f06a\00a0'),         // fa-exclamation-circle &nbsp;
  critical: (colour: red, fa-content: '\f7b9\00a0'),      // fa-radiation &nbsp;
);

/*
MessageType.Information => "<i class=\"fa-solid fa-circle-info\"></i>",
MessageType.Reminder => "<i class=\"fa-solid fa-bell\"></i>",
MessageType.Warning => "<i class=\"fa-solid fa-triangle-exclamation\"></i>",
MessageType.Error => "<i class=\"fa-solid fa-bomb\"></i>",
MessageType.Critical => "<i class=\"fa-solid fa-skull\"></i>",
_ => "<i class=\"fa-solid fa-circle\"></i>",

MessageType.Information => "alert-info",
MessageType.Reminder => "alert-secondary",
MessageType.Warning => "alert-warning",
MessageType.Error => "alert-danger",
MessageType.Critical => "alert-danger",
_ => "alert-light",
*/
@mixin fa-parser-message-type-list($fa-icons: ()) {
  @each $name,$values in $fa-icons {
    &-#{$name} {
      background-color: map.get($values, colour);
      // &::before {
      //   content: map.get($values, fa-content);
      //   padding-right: 20px;  // So doesn't overlap text
      // }
    }
  }
}

@mixin scrollable-content($h: '50vh') {
    height: $h;
    overflow: auto;
}

@mixin colour($config-or-theme) {

  .samples {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mat-mdc-table .mdc-data-table__row {
    height: 100px;
    }
    .mat-mdc-card.mat-mdc-card-outlined {
      border: 0!important;
    }
    mat-header-row.row {
      margin-left: inherit;
      margin-right: inherit;
    }
  }

  .parser-tag {
    border-radius: 11px;
    padding: 4px 10px 4px 10px;
    // white-space: nowrap;
    line-height: 28px;
  }

  form-group-control {
    &.has-hint .mat-mdc-form-field-subscript-wrapper {
      display: inherit !important;
    }
    &.bold-label label {
      font-weight: bold;
      color: black;
    }
  }

  // .parser-tag {}
  .scrollable-report-block {
    [style ^= "background-color"] {
      color: white;
    }
    [for="report_data"] {
      font-weight: bold;
    }
    @include scrollable-content(39vh);
  }

  .container.detailItemFG {
    max-width: inherit;
  }

  .samples mat-cell {
    padding: 0 3px;
    .mdc-floating-label--float-above {
      display: none;
    }
    .grid-data-row {
      height: 100px;
    }
  }

  .focus-name {
    font-weight: bold;
    text-transform: uppercase;
  }

  .scrollable-messages {
    &.both-message-types .mat-mdc-card-content {
      @include scrollable-content(31vh);
    }
    .mat-mdc-card-content {
      @include scrollable-content(63vh);
    }
  }

  .grid-small-text .mat-mdc-select-value-text {
    font-size: 0.875em;
  }

  .grid-x-small-text .mat-mdc-select-value-text {
    font-size: 0.7em;
  }

  .grid-xx-small-text .mat-mdc-select-value-text {
    font-size: 0.6em;
  }

  .mat-mdc-card {
    &-header .mat-mdc-card-header-text {
      width: 100% !important;
    }
    &-content {
      display: block;
      padding: 0 6px;
    }
  }

  // Remove Dialog width restrictions
  .excel-source-dialog.cdk-overlay-pane {
    width: inherit !important;
  }
  // .parser-message {
  //   @include identic.fa-icons-list($fa-parser-message-icons);

  //   &-none::before {
  //     color: grey;
  //     content: '\f002\00a0';  // fa-search &nbsp;
  //   }

  //   &-type {
  //     @include fa-parser-message-type-list($fa-parser-message-icons);
  //   }
  // }
}

@mixin typography($config-or-theme) {
  // Get the typography config from the theme.
  // $typography-config: mat.get-typography-config($config-or-theme);
  // e.g. font-family: mat.font-family($typography-config);

  // @include identic.fa-typography(parser-message);
}

@mixin theme($config-or-theme) {
  @include colour($config-or-theme);
  @include typography($config-or-theme);
}
