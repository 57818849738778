@use '@identic/core' as identic;

@mixin colour($config-or-theme) {
  .sample-grid {
    // Allow for sample metastasis height to expand
    .mat-mdc-table .mdc-data-table__row {
      height: unset;
    }
  }
}

@mixin typography($config-or-theme) {
  // Get the typography config from the theme.
  // $typography-config: mat.get-typography-config($config-or-theme);
  // e.g. font-family: mat.font-family($typography-config);

  // @include identic.fa-typography(parser-message);
}

@mixin theme($config-or-theme) {
  @include colour($config-or-theme);
  @include typography($config-or-theme);
}
